import { Reveal } from "@progress/kendo-react-animation";
import { SvgIcon } from "@progress/kendo-react-common";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
	ExpansionPanel,
	type ExpansionPanelActionEvent,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import cls from "classnames";
import { type ComponentProps, useState } from "react";
import { recycleBinIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { AttachmentUsage } from "../../../../../models/src/lib/enums/attachment.enum";
import type { IEntityAttachmentDto } from "../../../../../models/src/lib/interfaces/attachment.interface";
import type { IDocument } from "../../../../../models/src/lib/interfaces/document.interface";
import { CelerumSubmitButton } from "../celerum-buttons/celerum-buttons.component";
import { CelerumDocument } from "../celerum-document/celerum-document.component";
import { CelerumFormUploadInput } from "../celerum-form-elements/celerum-form-elements.component";
import styles from "./celerum-documents-form.module.css";

interface CelerumDocumentsFormProps {
	documents: IDocument[];
	entityId: number | string | undefined;
	entityUsage?: AttachmentUsage;
	onClose: () => void;
	handleAddAttachments?: (formState: IEntityAttachmentDto) => void;
	handleDeleteAttachment?: (attachmentId: string | number) => void;
	handleDownloadAttachment: (attachmentId: string | number) => void;
	isInteractive?: boolean;
	isInvoiced?: boolean;
}

type FormProps = ComponentProps<typeof Form>;

export const CelerumDocumentsForm = ({
	documents,
	entityId,
	entityUsage = AttachmentUsage.Trailer,
	isInteractive = true,
	isInvoiced = false,
	handleAddAttachments,
	onClose,
	handleDeleteAttachment,
	handleDownloadAttachment,
}: CelerumDocumentsFormProps) => {
	const [selectedDocument, setSelectedDocument] = useState<
		IDocument | undefined
	>(undefined);

	const [expanded, setExpanded] = useState<boolean | undefined>(false);

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const formData = new FormData();

		const newDataItem = { ...dataItem };
		if (newDataItem.files) {
			for (const file of newDataItem.files) {
				if (!documents.some((item: IDocument) => item.name === file.name)) {
					formData.append("files", file.getRawFile?.() || new Blob());
				}
			}
		}

		if (newDataItem.invoiceFiles) {
			for (const file of newDataItem.invoiceFiles) {
				if (!documents.some((item: IDocument) => item.name === file.name)) {
					formData.append("invoiceFiles", file.getRawFile?.() || new Blob());
				}
			}
		}

		entityId &&
			handleAddAttachments &&
			handleAddAttachments({
				documents: formData,
				entityId: entityId,
				entity: entityUsage,
			});
		onClose();
	};

	return (
		<>
			<div className={styles.container}>
				{documents.length > 0 ? (
					<div>
						{documents.map((item: IDocument) => (
							<div className={styles.item} key={item.id}>
								<CelerumDocument
									documentName={item.name}
									onClick={() => {
										handleDownloadAttachment(item.id);
									}}
								/>
								{isInteractive && (
									<div className={styles.icon}>
										{selectedDocument !== item ? (
											<SvgIcon
												width={18}
												icon={recycleBinIcon}
												className={styles.icon__delete}
												onClick={() => {
													setSelectedDocument(item);
												}}
											/>
										) : (
											<div>
												<span
													className={cls(
														styles.confirm,
														styles.confirm__cancel,
													)}
													onClick={() => setSelectedDocument(undefined)}
													onKeyUp={() => setSelectedDocument(undefined)}
												>
													Cancel
												</span>
												<span
													className={cls(styles.confirm, styles.confirm__ok)}
													onClick={() => {
														selectedDocument &&
															handleDeleteAttachment?.(selectedDocument.id);
													}}
													onKeyUp={() => {
														selectedDocument &&
															handleDeleteAttachment?.(selectedDocument.id);
													}}
												>
													Ok
												</span>
											</div>
										)}
									</div>
								)}
							</div>
						))}
					</div>
				) : (
					<p>No documents found.</p>
				)}
			</div>

			<Form
				onSubmit={handleSubmit}
				render={(formRenderProps) => (
					<FormElement>
						{isInteractive && (
							<fieldset className="k-form-fieldset">
								<div className={styles.field}>
									<Field
										label="Upload documents"
										name="files"
										component={CelerumFormUploadInput}
									/>
								</div>
							</fieldset>
						)}

						{isInvoiced && (
							<div>
								<ExpansionPanel
									title="Use this field to upload your generated invoice document(s)."
									expanded={expanded !== undefined}
									onAction={(event: ExpansionPanelActionEvent) => {
										setExpanded(event.expanded ? undefined : true);
									}}
								>
									<Reveal>
										{expanded && (
											<ExpansionPanelContent>
												<div onKeyDown={(e) => e.stopPropagation()}>
													<Field
														label=""
														name="invoiceFiles"
														component={CelerumFormUploadInput}
													/>
												</div>
											</ExpansionPanelContent>
										)}
									</Reveal>
								</ExpansionPanel>
							</div>
						)}
						<CelerumSubmitButton
							type="submit"
							disabled={!formRenderProps.allowSubmit}
							title="Submit"
						/>
					</FormElement>
				)}
			/>
		</>
	);
};
