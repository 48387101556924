import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import cls from "classnames";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { fetchAttachmentUriAction } from "../../../libs/attachments/data-access/src/lib/attachments.slice";
import { LOAD_STATUS_MAPPINGS } from "../../../libs/common/models/src/lib/constants/load.constants";
import { NOT_AVAILABLE } from "../../../libs/common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../libs/common/models/src/lib/enums/actions.enum";
import { AttachmentUsage } from "../../../libs/common/models/src/lib/enums/attachment.enum";
import {
	ModalSize,
	ModalType,
} from "../../../libs/common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../libs/common/models/src/lib/enums/parent-type.enum";
import type { ILoadDetails } from "../../../libs/common/models/src/lib/interfaces/load.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../libs/common/stores/src/lib/utils";
import { CelerumMoreDetailsButtonGrid } from "../../../libs/common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../libs/common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDetailsHeader } from "../../../libs/common/ui/src/lib/components/celerum-details-header/celerum-details-header.component";
import { CelerumDocumentsForm } from "../../../libs/common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumModal } from "../../../libs/common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { handleOptions } from "../../../libs/common/utils/src/lib/helpers/action.helpers";
import { generateCreatedByText } from "../../../libs/common/utils/src/lib/helpers/date.helpers";
import { fetchGoodsByLoadIdAction } from "../../../libs/goods/data-access/src/lib/goods.slice";
import { GoodsInformationFeature } from "../../../libs/goods/feature/src/lib/goods-information-feature/goods-information-feature";
import { LegsInformationFeature } from "../../../libs/legs/feature/src/lib/legs-information-feature/legs-information-feature";
import {
	clearLoadAction,
	fetchLoadByIdAction,
	getClearCustomsAttachmentsAction,
} from "../../../libs/loads/data-access/src/lib/loads.slice";
import {
	canCancel,
	canDelete,
	canDownloadDocuments,
	canDuplicate,
	canGenerateManifest,
	canPause,
	canPrintAllDocuments,
	canResume,
} from "../../../libs/loads/feature/src/lib/helpers/load-option.helpers";
import { useLoadActionSelected } from "../../../libs/loads/feature/src/lib/hooks/use-load-action-selected.hook";
import { LoadInformationFeature } from "../../../libs/loads/feature/src/lib/load-information-feature/load-information-feature";
import styles from "./load-details.module.css";

export const LoadDetailsPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();
	const handleActionSelected = useLoadActionSelected();

	const { load, clearCustomsDocuments, loading } = useAppSelector(
		(state) => state.loads,
	);
	const { navbarExpanded } = useAppSelector((state) => state.userInterface);

	useTitle(`Load ${id} - ${load?.uniqueId}`);

	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [showModal, setShowModal] = useState<{
		create: boolean;
		actions: boolean;
		documents: boolean;
	}>({
		create: false,
		actions: false,
		documents: false,
	});

	const renderedHeaderSubtitle = useMemo(() => {
		if (load) {
			return generateCreatedByText(
				load.createdByUser?.fullName ?? NOT_AVAILABLE,
				load.creationTime,
			);
		}
	}, [load]);

	const handleMoreOptions = {
		canCancel,
		canDelete,
		canPause,
		canDuplicate,
		canResume,
		canGenerateManifest,
		canPrintAllDocuments,
		canDownloadDocuments,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent) => {
		const value = event.item.text as keyof typeof CelerumActions;
		if (!value) return;
		setSelectedAction(CelerumActions[value]);
		if (CelerumActions[value] === CelerumActions["Clear Customs Documents"]) {
			setShowModal({ ...showModal, documents: true });
		} else {
			setShowModal({ ...showModal, actions: true });
		}
	};

	const closeModal = () => {
		setShowModal({
			...showModal,
			create: false,
			actions: false,
			documents: false,
		});
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatchWithNotifications({
			action: fetchAttachmentUriAction,
			payload: attachmentId,
		});
	};

	useEffect(() => {
		if (id && !Number.isNaN(Number.parseInt(id))) {
			dispatchWithNotifications({
				action: fetchLoadByIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch load.",
			});
			dispatchWithNotifications({
				action: fetchGoodsByLoadIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch goods.",
			});
			dispatchWithNotifications({
				action: getClearCustomsAttachmentsAction,
				payload: Number(id),
				errorMessage: "Could not fetch documents.",
			});
		}

		return () => {
			dispatch(clearLoadAction());
		};
	}, [id, dispatch, dispatchWithNotifications]);

	useEffect(() => {
		if (loading.status) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.status]);

	return (
		<div
			className={navbarExpanded ? styles.containerOpen : styles.containerClose}
		>
			<CelerumDetailsHeader
				loading={loading.load}
				title={load?.uniqueId ?? "New Load"}
				subtitle={renderedHeaderSubtitle}
				status={LOAD_STATUS_MAPPINGS[load?.status ?? 1]}
			>
				{load && (
					<CelerumMoreDetailsButtonGrid
						items={handleOptions(load, handleMoreOptions)}
						handleOnSelect={handleMoreOptionsSelected}
						text={"Actions"}
					/>
				)}
			</CelerumDetailsHeader>
			<div
				className={
					navbarExpanded
						? cls(styles.cardsContainer, styles.cardsContainer__open)
						: cls(styles.cardsContainer, styles.cardsContainer__close)
				}
			>
				<LoadInformationFeature className={styles.loadInfoContainer} />
				<GoodsInformationFeature
					className={styles.goodsContainer}
					parentType={ParentType.Load}
					parentId={id}
					uniqueId={load?.uniqueId}
				/>
				<LegsInformationFeature
					className={styles.legsContainer}
					parentType={ParentType.Load}
					parentId={id}
				/>
			</div>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this load?`
				}
				entityName="load"
				entityProperty="with ID"
				entityValue={load?.uniqueId}
				isOpen={showModal.actions}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The load cannot be recovered."
						: "This action can be reverted afterwards."
				}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={() => {
					handleActionSelected(
						selectedAction,
						(load as ILoadDetails).id,
						(load as ILoadDetails).uniqueId,
					);
					closeModal();
					if (
						selectedAction === CelerumActions.Delete ||
						selectedAction === CelerumActions.Cancel
					) {
						navigate("/loads");
					}
				}}
				handleClose={closeModal}
			/>
			<CelerumModal
				title="View documents"
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.documents}
			>
				<CelerumDocumentsForm
					documents={clearCustomsDocuments}
					onClose={closeModal}
					entityId={load?.id}
					entityUsage={AttachmentUsage.Trailer}
					handleDownloadAttachment={handleDownloadAttachment}
					isInteractive={false}
				/>
			</CelerumModal>
		</div>
	);
};
