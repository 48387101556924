import { useEffect, useMemo, useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../../attachments/data-access/src/lib/attachments.slice";
import { editGridIconWhite } from "../../../../../common/models/src/lib/constants/icon.constants";
import { AttachmentUsage } from "../../../../../common/models/src/lib/enums/attachment.enum";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../../common/models/src/lib/interfaces/attachment.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumViewDetailsButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumDocumentsForm } from "../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { fetchCustomersAction } from "../../../../../customers/data-access/src/lib/customers.slice";
import { fetchJobTypesAction } from "../../../../data-access/src/lib/job-types.slice";
import {
	attachDocumentsToJobAction,
	deleteDocumentFromJobAction,
} from "../../../../data-access/src/lib/jobs.slice";
import { EditJobInformationForm } from "../components/edit-job-information-form/edit-job-information-form.component";
import { JobInformationCardContainer } from "../components/job-information-card-container/job-information-card-container.component";
import styles from "./job-information-feature.module.css";

interface JobInformationFeatureProps {
	isJobInvoiced: boolean;
	className: string | undefined;
}

export const JobInformationFeature = ({
	isJobInvoiced,
	className,
}: JobInformationFeatureProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const { job, loading } = useAppSelector((state) => state.jobs);

	const [showModal, setShowModal] = useState<{
		documents: boolean;
		update: boolean;
	}>({
		documents: false,
		update: false,
	});
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const renderedJobDetails = useMemo(() => {
		if (job) {
			return {
				...job,
				startDate: new Date(job.startDate),
				endDate: new Date(job.endDate),
				price: Math.round(job.price * 100) / 100,
			};
		}
	}, [job]);

	const closeModal = () => {
		setShowModal({ documents: false, update: false });
	};

	const handleAddAttachments = async (formState: IEntityAttachmentDto) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				successMessage: "Document successfully uploaded.",
				errorMessage: "Could not upload document.",
			});
			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(attachDocumentsToJobAction(actionResult.payload));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteAttachment = async (attachmentId: number | string) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				successMessage: "Document successfully deleted.",
				errorMessage: "Could not delete document.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatch(deleteDocumentFromJobAction(attachmentId));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchJobTypesAction,
			errorMessage: "Could not fetch job types.",
		});
		dispatchWithNotifications({
			action: fetchCustomersAction,
			payload: {},
			errorMessage: "Could not fetch customers.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<CelerumCustomCard
				buttonName="Edit Info"
				buttonSvgIcon={editGridIconWhite}
				onClick={() => setShowModal({ ...showModal, update: true })}
				headerComponents={
					<div className={styles.viewDetailsButtonContainer}>
						<CelerumViewDetailsButton
							title={`View Documents (${job?.documents?.length ?? 0})`}
							onClick={() => setShowModal({ ...showModal, documents: true })}
							disabled={loading.job || !job}
						/>
					</div>
				}
				title="Job Information"
				className={className}
				disabled={loading.job || !job || isJobInvoiced}
			>
				{loading.job ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : job ? (
					<JobInformationCardContainer jobDetails={job} />
				) : (
					!loading.job && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>There was a problem while loading job information.</span>
								<span>Please try again later.</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>

			{job && (
				<>
					<CelerumModal
						title="View Documents"
						width={ModalSize.Small}
						visible={showModal.documents}
						toggleDialog={closeModal}
					>
						<CelerumDocumentsForm
							documents={job.documents}
							entityId={job.id}
							entityUsage={AttachmentUsage.Job}
							onClose={closeModal}
							handleAddAttachments={handleAddAttachments}
							handleDeleteAttachment={handleDeleteAttachment}
							handleDownloadAttachment={handleDownloadAttachment}
							isInteractive={!isJobInvoiced}
							isInvoiced={isJobInvoiced}
						/>
					</CelerumModal>

					{renderedJobDetails && (
						<CelerumModal
							title="Edit Job Information"
							width={modalSize}
							visible={showModal.update}
							toggleDialog={closeModal}
						>
							<EditJobInformationForm
								formState={renderedJobDetails}
								onClose={closeModal}
								setModalSize={setModalSize}
							/>
						</CelerumModal>
					)}
				</>
			)}
		</>
	);
};
