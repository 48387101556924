import { useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../../../common/models/src/lib/interfaces/attachment.interface";
import type { IQualification } from "../../../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumViewDetailsButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumDocumentsForm } from "../../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumModal } from "../../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import {
	attachDocumentsToQualificationAction,
	deleteQualificationAttachmentAction,
} from "../../../../../data-access/src/lib/drivers.slice";
import styles from "./qualification-accordion-content.module.css";

interface QualificationAccordionContentProps {
	qualification: IQualification;
}

export const QualificationAccordionContent = ({
	qualification,
}: QualificationAccordionContentProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const [showModal, setShowModal] = useState<boolean>(false);

	const handleAddAttachments = async (formState: IEntityAttachmentDto) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				successMessage: "Attachments successfully uploaded.",
				errorMessage: "Could not upload attachments.",
			});

			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(
					attachDocumentsToQualificationAction({
						documents: actionResult.payload,
						entityId: formState.entityId,
					}),
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadQualificationDocument = (
		attachmentId: number | string,
	) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	const handleDeleteDocument = async (attachmentId: number | string) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				successMessage: "Attachment successfully deleted.",
				errorMessage: "Could not delete attachment.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatch(deleteQualificationAttachmentAction(Number(attachmentId)));
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.section}>
					<div className={styles.content}>
						<span className={styles.content__title}>Date Taken</span>
						<span className={styles.content__value}>
							{new Date(qualification.dateTaken).toLocaleDateString()}
						</span>
					</div>
					<div className={styles.content}>
						<span className={styles.content__title}>Expiration Date</span>
						<span className={styles.content__value}>
							{new Date(qualification.expirationDate).toLocaleDateString()}
						</span>
					</div>
				</div>
				<div className={styles.section}>
					<div className={styles.content}>
						<span className={styles.content__title}>Constraints</span>
						<div className={styles.content__array}>
							{qualification.constraints.map((constraint) => (
								<CelerumTag
									key={constraint.id}
									name={constraint.name}
									className={styles.content__value}
								/>
							))}
						</div>
					</div>
				</div>
				<div className={styles.section}>
					<div style={{ alignSelf: "flex-end" }}>
						<CelerumViewDetailsButton
							onClick={() => {
								setShowModal(true);
							}}
							title={`View Documents (${qualification?.documents.length ?? 0})`}
						/>
					</div>
				</div>
			</div>
			<CelerumModal
				title="View Documents"
				width={ModalSize.Small}
				visible={showModal}
				toggleDialog={() => setShowModal(false)}
			>
				<CelerumDocumentsForm
					documents={qualification.documents ? qualification.documents : []}
					isInteractive={true}
					entityId={qualification.id}
					onClose={() => setShowModal(false)}
					handleAddAttachments={handleAddAttachments}
					handleDeleteAttachment={handleDeleteDocument}
					handleDownloadAttachment={handleDownloadQualificationDocument}
					entityUsage={AttachmentUsage.Qualification}
				/>
			</CelerumModal>
		</>
	);
};
