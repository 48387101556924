import {
	Drawer,
	DrawerContent,
	type DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { type ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { DrawerItemType } from "../../../../../models/src/lib/enums/drawer-item-types.enum";
import type { CelerumDrawerItemProps } from "../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import type { UserProfileInfo } from "../../../../../models/src/lib/interfaces/user.interface";
import styles from "./celerum-navbar.module.css";
import { CustomDrawerItem } from "./components/custom-drawer-item/custom-drawer-item.component";

interface CelerumNavbarProps {
	children: ReactNode;
	routes: CelerumDrawerItemProps[];
	handleNavbarExpanded: (expanded: boolean) => void;
	handleClickOnBusinessUnit: () => void;
	logoutHandler: () => void;
	userProfile: UserProfileInfo;
	navbarExpanded: boolean;
}

export const CelerumNavbar = ({
	navbarExpanded,
	handleClickOnBusinessUnit,
	logoutHandler,
	userProfile,
	handleNavbarExpanded,
	routes,
	children,
}: CelerumNavbarProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [currentMenuItems, setCurrentMenuItems] = useState<
		CelerumDrawerItemProps[]
	>([
		{ itemType: DrawerItemType.Logo, route: "/", logo: styles.logoPic },
		{
			itemType: DrawerItemType.OpenCloseIcon,
			setExpanded: handleNavbarExpanded,
		},
		{
			itemType: DrawerItemType.Profile,
			userProfile,
			handleClickOnBusinessUnit: handleClickOnBusinessUnit,
		},
		...routes,
		{
			itemType: DrawerItemType.Logout,
			svgIcon: logoutIcon,
			logoutHandler: logoutHandler,
			text: "Log out",
		},
	]);

	const areLinksVisible = (
		currentItem: CelerumDrawerItemProps,
		item: CelerumDrawerItemProps,
	) => {
		const isParent = item.linksVisible !== undefined;
		if (currentItem === item && isParent) {
			return !item.linksVisible;
		}
		if (isParent) {
			return currentItem.parentId === item.id;
		}
		return item.linksVisible;
	};

	const onSelect = (event: DrawerSelectEvent) => {
		const selectedItem = currentMenuItems.find(
			(item: CelerumDrawerItemProps) => item.id === event.itemTarget.props.id,
		) as CelerumDrawerItemProps;

		if (selectedItem.itemType === DrawerItemType.Category && !navbarExpanded) {
			handleNavbarExpanded(true);
		}

		setCurrentMenuItems(
			currentMenuItems.map((item: CelerumDrawerItemProps) => {
				const { id, ...others } = item;
				const isCurrentItem = selectedItem.id === id;

				return {
					...others,
					selected: isCurrentItem || selectedItem.parentId === id,
					linksVisible: areLinksVisible(selectedItem, item),
					id,
				};
			}),
		);
		navigate(selectedItem.route);
	};

	const renderedMenuItems = useMemo(() => {
		return currentMenuItems.map((item: CelerumDrawerItemProps) => {
			switch (item.itemType) {
				case DrawerItemType.Link: {
					const parent = currentMenuItems.find(
						(parent) => parent.id === item.parentId,
					);
					return {
						...item,
						visible: parent?.linksVisible && navbarExpanded,
						navbarExpanded: navbarExpanded,
						selected: item.route === `/${location.pathname.split("/")[1]}`,
					};
				}
				default:
					return {
						...item,
						visible: true,
						navbarExpanded: navbarExpanded,
					};
			}
		});
	}, [currentMenuItems, navbarExpanded, location.pathname]);

	return (
		<Drawer
			className={styles.root}
			expanded={navbarExpanded}
			miniWidth={100}
			position={"start"}
			mode={"push"}
			mini={true}
			onSelect={onSelect}
			items={renderedMenuItems}
			item={CustomDrawerItem}
		>
			<div
				style={{
					width: "100%",
					height: "100%",
					marginLeft: "0px",
					display: "grid",
					gridTemplateRows: "1fr",
				}}
			>
				<DrawerContent
					style={{
						margin: "16px",
						marginLeft: "0px",
						display: "grid",
						gridTemplateRows: "1fr",
						overflow: "hidden",
					}}
				>
					{children}
				</DrawerContent>
			</div>
		</Drawer>
	);
};
