import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { CelerumActions } from "../../../libs/common/models/src/lib/enums/actions.enum";
import { ModalType } from "../../../libs/common/models/src/lib/enums/modal.enums";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../libs/common/stores/src/lib/utils";
import { CelerumMoreDetailsButtonGrid } from "../../../libs/common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../libs/common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDetailsHeader } from "../../../libs/common/ui/src/lib/components/celerum-details-header/celerum-details-header.component";
import { handleOptions } from "../../../libs/common/utils/src/lib/helpers/action.helpers";
import {
	fetchDriverByIdAction,
	fetchQualificationsByDriverIdAction,
} from "../../../libs/drivers/data-access/src/lib/drivers.slice";
import { DriverInformationFeature } from "../../../libs/drivers/feature/src/lib/driver-information-feature/driver-information-feature";
import { canDelete } from "../../../libs/drivers/feature/src/lib/helpers/driver-option.helpers";
import { useDriverActionSelected } from "../../../libs/drivers/feature/src/lib/hooks/use-driver-action-selected.hook";
import { QualificationsInformationFeature } from "../../../libs/drivers/feature/src/lib/qualifications-information-feature/qualifications-information-feature";
import styles from "./driver-details.module.css";

export const DriverDetailsPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const handleSelectedAction = useDriverActionSelected();

	const { navbarExpanded, driver, loading } = useAppSelector((state) => ({
		navbarExpanded: state.userInterface.navbarExpanded,
		driver: state.drivers.driver,
		loading: state.drivers.loading,
	}));

	useTitle(`Driver ${id} - ${driver?.fullName}`);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);

	const handleMoreOptions = {
		canDelete,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent) => {
		const value = event.item.text as keyof typeof CelerumActions;
		if (!value) return;
		setSelectedAction(CelerumActions[value]);
		setShowModal(true);
	};

	useEffect(() => {
		if (id && !Number.isNaN(Number.parseInt(id))) {
			dispatchWithNotifications({
				action: fetchDriverByIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch driver.",
			});
			dispatchWithNotifications({
				action: fetchQualificationsByDriverIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch driver qualifications.",
			});
		}
	}, [id, dispatchWithNotifications]);

	return (
		<div
			className={navbarExpanded ? styles.containerOpen : styles.containerClose}
		>
			<CelerumDetailsHeader
				loading={loading.driver}
				title={driver?.fullName ?? "New Driver"}
				subtitle={driver?.phones.length ? driver.phones[0] : "No phone number"}
			>
				{driver && (
					<CelerumMoreDetailsButtonGrid
						items={handleOptions(driver, handleMoreOptions)}
						handleOnSelect={handleMoreOptionsSelected}
						text={"Actions"}
					/>
				)}
			</CelerumDetailsHeader>
			<div className={styles.cardsContainer}>
				<DriverInformationFeature className={styles.driverContainer} />
				<QualificationsInformationFeature
					className={styles.qualificationsContainer}
				/>
			</div>
			{driver && (
				<CelerumConfirmModal
					title={
						selectedAction &&
						`Are you sure you want to ${CelerumActions[selectedAction]
							?.toString()
							.toLowerCase()} this job?`
					}
					entityName="driver"
					entityProperty="named"
					entityValue={driver.fullName}
					isOpen={showModal}
					subtitle={"The driver cannot be recovered."}
					type={ModalType.Delete}
					handleSubmit={() => {
						handleSelectedAction(selectedAction, driver.id, driver.fullName);
						setShowModal(false);
						navigate("/drivers");
					}}
					handleClose={() => setShowModal(false)}
				/>
			)}
		</div>
	);
};
