import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type {
	IDriver,
	IDriverRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/driver.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddItemsInInput } from "../../../../../../common/ui/src/lib/components/celerum-add-items-in-input/celerum-add-items-in-inputs.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDatePicker,
	CelerumFormInput,
	CelerumFormTextArea,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createDriverAction,
	updateDriverAction,
} from "../../../../../data-access/src/lib/drivers.slice";
import styles from "./driver-form.module.css";

interface DriverFormProps {
	formState?: IDriver;
	onClose: () => void;
}

export const DriverForm = ({ onClose, formState }: DriverFormProps) => {
	const navigate = useNavigate();

	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { loading } = useAppSelector((state) => ({
		loading: state.drivers.loading.grid,
	}));

	const [phones, setPhones] = useState<string[]>(formState?.phones ?? []);
	const [emails, setEmails] = useState<string[]>(formState?.emails ?? []);
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		if (!event.isValid) {
			return;
		}

		const { firstName, lastName, licenceExpirationDate, notes } = event.values;

		const requestObject: IDriverRequestDto = {
			id: formState?.id,
			firstName,
			lastName,
			licenceExpirationDate: toISOStringWithoutTimezoneOffset(
				licenceExpirationDate,
			),
			emails,
			phones,
			notes,
		};

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateDriverAction,
				payload: requestObject,
				errorMessage: `Could not update driver ${firstName} ${lastName}.`,
			});

			if (updateDriverAction.fulfilled.match(actionResult)) {
				onClose();
			}
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createDriverAction,
				payload: requestObject,
				errorMessage: `Could not create driver ${firstName} ${lastName}.`,
			});

			if (createDriverAction.fulfilled.match(actionResult)) {
				onClose();
				navigate(`/drivers/${actionResult.payload.id}`, {
					state: { from: window.location.pathname },
				});
			}
		}
	};

	return (
		<Form
			initialValues={formState}
			onSubmitClick={handleSubmit}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.container}>
							<div>
								<Field
									id="firstName"
									name="firstName"
									label="First Name"
									component={CelerumFormInput}
									maxLength={150}
									validator={requiredValidator}
									required
									focused="true"
								/>
								<Field
									id="lastName"
									name="lastName"
									label="Last Name"
									component={CelerumFormInput}
									maxLength={150}
									validator={requiredValidator}
									required
								/>
								<CelerumAddItemsInInput
									label="Phone Number"
									name="phone"
									id="phone"
									buttonName="Add another phone number"
									result={phones}
									setResult={setPhones}
									setIsFormModified={setIsFormModified}
								/>
							</div>
							<div>
								<Field
									id="licenceExpirationDate"
									name="licenceExpirationDate"
									label="License Expiration Date"
									component={CelerumFormDatePicker}
									validator={requiredValidator}
									required
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									type="text"
									defaultValue={undefined}
								/>
								<CelerumAddItemsInInput
									label="Email"
									name="email"
									id="email"
									buttonName="Add another email"
									result={emails}
									setResult={setEmails}
									setIsFormModified={setIsFormModified}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={
							(!isFormModified && !formRenderProps.allowSubmit) || loading
						}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
