import { cartIcon, dollarIcon } from "@progress/kendo-svg-icons";
import { useMemo } from "react";
import {
	customerIcon,
	documentsIcon,
	operatorIcon,
	percentageIcon,
	profitIcon,
	truckCompleteIcon,
	truckIcon,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IJobDetails } from "../../../../../../common/models/src/lib/interfaces/job.interface";
import { CelerumDetailsCard } from "../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import {
	getFormattedCurrency,
	getFormattedValue,
} from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import styles from "./job-information-grid.module.css";

interface JobInformationGridProps {
	jobDetails: IJobDetails;
}

export const JobInformationGrid = ({ jobDetails }: JobInformationGridProps) => {
	const {
		customer,
		jobType,
		price,
		purchaseOrderNumber,
		profitPercent,
		cost,
		assignedUser,
		associatedLoads,
		vatRate,
		useVat,
		containerNumber,
		containerPin,
		containerSealNumber,
	} = jobDetails;

	const checkCurrencyFormat = (value: number) => {
		const currencyCode = customer?.currency?.code || "USD";
		return getFormattedCurrency(value || 0, currencyCode);
	};

	const renderedAssociatedLoads = useMemo(() => {
		if (associatedLoads && associatedLoads.length > 0) {
			return associatedLoads.map((load) => ({
				id: load.id,
				name: load.uniqueId,
			}));
		}
		return [];
	}, [associatedLoads]) as IBase[];

	return (
		<div className={styles.container}>
			<CelerumDetailsCard
				svgIcon={customerIcon}
				label="Customer"
				value={customer?.name ?? NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIcon={truckIcon}
				label="Job Type"
				value={jobType?.name ?? NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIcon={dollarIcon}
				label="Price"
				value={price ? checkCurrencyFormat(price) : NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIconStyle={styles.svgIcon}
				svgIcon={operatorIcon}
				label="Operator"
				value={assignedUser?.fullName ?? NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIcon={cartIcon}
				label="Purchase Order No."
				value={purchaseOrderNumber ?? NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIcon={profitIcon}
				label="Total Cost of Legs"
				value={cost ? checkCurrencyFormat(cost) : NOT_AVAILABLE}
			/>
			<CelerumDetailsCard
				svgIconStyle={styles.svgIcon}
				svgIcon={operatorIcon}
				label="VAT"
				value={useVat ? "Yes" : "No"}
			/>
			<CelerumDetailsCard
				svgIconStyle={styles.svgIcon}
				svgIcon={operatorIcon}
				label="VAT Rate"
				value={
					vatRate && vatRate !== -1
						? `${getFormattedValue(vatRate)}%`
						: NOT_AVAILABLE
				}
			/>
			<CelerumDetailsCard
				svgIcon={percentageIcon}
				label="Profit"
				value={
					profitPercent
						? `${getFormattedValue(profitPercent, customer?.currency?.code)}%`
						: NOT_AVAILABLE
				}
			/>
			<CelerumDetailsCard
				svgIcon={truckCompleteIcon}
				label="Associated Loads"
				value={
					renderedAssociatedLoads && renderedAssociatedLoads.length > 0
						? renderedAssociatedLoads
						: NOT_AVAILABLE
				}
				type={
					renderedAssociatedLoads && renderedAssociatedLoads.length > 0
						? "array"
						: ""
				}
				navigationUrl={"/loads"}
			/>
			{jobDetails?.jobType?.isContainer && (
				<>
					<CelerumDetailsCard
						svgIcon={documentsIcon}
						label="Container Number"
						value={containerNumber ?? NOT_AVAILABLE}
					/>
					<CelerumDetailsCard
						svgIcon={documentsIcon}
						label="Container Pin"
						value={containerPin ?? NOT_AVAILABLE}
					/>
					<CelerumDetailsCard
						svgIcon={documentsIcon}
						label="Container Seal Number"
						value={containerSealNumber ?? NOT_AVAILABLE}
					/>
				</>
			)}
		</div>
	);
};
