import { SvgIcon } from "@progress/kendo-react-common";
import type { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { useState } from "react";
import { arrowsIcon } from "../../../../../../common/models/src/lib/constants/icon.constants";
import type { ICurrencyExchangeRate } from "../../../../../../common/models/src/lib/interfaces/currency-exchange-rate.interface";
import type { ICurrency } from "../../../../../../common/models/src/lib/interfaces/currency.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormDropDownList,
	CelerumFormInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createCurrencyExchangeRateAction,
	updateCurrencyExchangeRateAction,
} from "../../../../../data-access/src/lib/currency-exchange-rate.slice";
import styles from "./exchange-rates-form.module.css";

interface ExchangeRatesFormProps {
	formState: ICurrencyExchangeRate | undefined;
	onClose: () => void;
	currentPairs: ICurrencyExchangeRate[];
}

export const ExchangeRatesForm = ({
	formState,
	onClose,
	currentPairs,
}: ExchangeRatesFormProps) => {
	const { loading } = useAppSelector((state) => state.currencyExchangeRates);

	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data: currencies } = useAppSelector((state) => state.currencies);
	const [toCurrencies, setToCurrencies] = useState<ICurrency[]>([]);

	const getExchangeRateText = (rate: ICurrencyExchangeRate | undefined) => {
		return rate
			? `1 ${rate.fromCurrency?.code} = ${rate.exchangeRate} ${rate.toCurrency?.code}`
			: "";
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		if (formState) {
			const requestObject = {
				exchangeRate: dataItem?.exchangeRate,
				id: dataItem?.id,
			};
			const actionResult = await dispatchWithNotifications({
				action: updateCurrencyExchangeRateAction,
				payload: requestObject,
				successMessage: `Currency exchange rate ${getExchangeRateText(
					formState,
				)} updated successfully.`,
				errorMessage: `Could not update currency exchange rate ${getExchangeRateText(
					formState,
				)}.`,
			});
			updateCurrencyExchangeRateAction.fulfilled.match(actionResult) &&
				onClose();
		} else {
			const requestObject = {
				fromCurrencyId: dataItem?.fromCurrency.id,
				toCurrencyId: dataItem?.toCurrency.id,
				exchangeRate: dataItem?.exchangeRate,
			};
			const actionResult = await dispatchWithNotifications({
				action: createCurrencyExchangeRateAction,
				payload: requestObject,
				successMessage: `Currency exchange rate 1 ${dataItem?.fromCurrency?.code} = ${dataItem?.exchangeRate} ${dataItem?.toCurrency?.code} created successfully.`,
				errorMessage: `Could not create currency exchange rate 1 ${dataItem?.fromCurrency?.code} = ${dataItem?.exchangeRate} ${dataItem?.toCurrency?.code}.`,
			});
			createCurrencyExchangeRateAction.fulfilled.match(actionResult) &&
				onClose();
		}
	};

	const onFromCurrencyDropDownChange = (event: DropDownListChangeEvent) => {
		const selectedCurrency = event.target.value as ICurrency;
		const unavailableCurrencies = new Set([selectedCurrency.id]);

		for (const currencyPair of currentPairs) {
			if (currencyPair.fromCurrency.id === selectedCurrency?.id) {
				unavailableCurrencies.add(currencyPair.toCurrency.id);
			}
		}

		const availableCurrencies = currencies.reduce(
			(accumulator: ICurrency[], currentValue) =>
				unavailableCurrencies.has(currentValue.id)
					? accumulator
					: accumulator.concat(currentValue),
			[],
		);
		setToCurrencies(availableCurrencies);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<div className={styles.formContainer}>
						<div className={styles.formMain}>
							<Field
								id="fromCurrency"
								name="fromCurrency"
								data={currencies}
								dataItemKey="id"
								textField="code"
								label="Currency"
								component={CelerumFormDropDownList}
								onChange={onFromCurrencyDropDownChange}
								disabled={!!formState}
								validator={requiredValidator}
								required
							/>
							<CelerumDisabledInput value={1} label="Unit" />
						</div>
						<div className={styles.formIcon}>
							<SvgIcon
								className={styles.arrowIconStyle}
								icon={arrowsIcon}
								width={20}
							/>
						</div>
						<div className={styles.formMain}>
							<Field
								id="toCurrency"
								name="toCurrency"
								data={toCurrencies}
								dataItemKey="id"
								textField="code"
								label="Currency"
								component={CelerumFormDropDownList}
								disabled={toCurrencies.length === 0 || !!formState}
								validator={requiredValidator}
								required
							/>
							<Field
								id="exchangeRate"
								name="exchangeRate"
								label="Exchange Rate"
								component={CelerumFormInput}
								validator={requiredValidator}
								required
							/>
						</div>
					</div>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title={formState ? "Save Changes" : "Add Exchange Rate"}
					/>
				</FormElement>
			)}
		/>
	);
};
