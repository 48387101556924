import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
	type InferType,
	array,
	bool,
	date,
	mixed,
	number,
	object,
	string,
} from "yup";
import { DFlex } from "../display/DFlex";
import { noInvalidDate, noNaN } from "../helpers";
import { useOnAddLocation } from "../helpersReact";
import {
	GenericForm,
	ICheckbox,
	IDateTime,
	IDropzone,
	IMultiSelect,
	INumber,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";

const JobSchema = object({
	id: number().transform(noNaN).label("ID"),
	jobTypeId: number().label("Job Type").required(),
	customerId: number().label("Customer").required(),
	isPurchaseOrderNumberMandatory: bool(),
	purchaseOrderNumber: string()
		.label("Purchase Order Number")
		.when("isPurchaseOrderNumberMandatory", {
			is: true,
			// biome-ignore lint/suspicious/noThenProperty: it is Yup's API
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
	startLocationId: number().label("Start Location").required(),
	startDate: date().transform(noInvalidDate).label("Start Date").required(),
	endLocationId: number().label("End Location").required(),
	endDate: date().transform(noInvalidDate).label("End Date"),
	price: number().min(0).transform(noNaN).label("Price"),
	assignedTo: string().label("Assigned To").required(),
	notes: string().label("Notes"),
	goodsInfo: object({
		constraints: array()
			.of(number().required().label("Constraint"))
			.label("Constraints"),
		name: string().label("Goods Description").required(),
		quantity: number()
			.transform(noNaN)
			.label("Quantity")
			.required()
			.moreThan(0),
		weight: number().transform(noNaN).label("Weight").required().moreThan(0),
	}),
	documents: array().of(mixed<File>().required()).label("Documents"), // not part of the post
	associatedDocuments: array().of(mixed<File>().required()), // not part of the post
	containerNumber: string().label("Container Number"),
	containerPin: string().label("Container Pin"),
	containerSealNumber: string().label("Container Seal Number"),
	useVat: bool().label("Use VAT").default(true).required(),
});

type Job = InferType<typeof JobSchema>;
export type JobFormProps = {
	defaultValues?: Partial<Job>;
	lJobTypes: LoadOptionsFn;
	lCustomers: LoadOptionsFn;
	lUsers: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lConstraints: LoadOptionsFn;
	onSubmit: (data: Job) => Promise<unknown>;
	onAddLocation?: (callback: (value: number) => void) => void;
};

export const JobForm = ({
	defaultValues,
	lJobTypes,
	lCustomers,
	lUsers,
	lLocations,
	lConstraints,
	onSubmit,
	onAddLocation,
}: JobFormProps) => {
	const form = useForm<Job>({
		resolver: yupResolver(JobSchema),
		defaultValues,
	});

	const startLocationButton = useOnAddLocation<Job>(
		"startLocationId",
		form.setValue,
		onAddLocation,
	);

	const endLocationButton = useOnAddLocation<Job>(
		"endLocationId",
		form.setValue,
		onAddLocation,
	);

	return (
		<GenericForm
			form={form}
			schema={JobSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ISelect n="jobTypeId" l={lJobTypes} />
					<ISelect
						n="startLocationId"
						l={lLocations}
						label={
							<>
								Start Location
								{startLocationButton}
							</>
						}
					/>
					<IDateTime n="startDate" />
					<IText n="purchaseOrderNumber" />
					<ISelect n="assignedTo" l={lUsers} />
					<IDropzone n="documents" />
					<ICheckbox n="useVat" />
				</div>
				<div>
					<ISelect n="customerId" l={lCustomers} />
					<ISelect
						n="endLocationId"
						l={lLocations}
						label={
							<>
								End Location
								{endLocationButton}
							</>
						}
					/>
					<IDateTime n="endDate" />
					<INumber n="price" step={0.01} />
					<ITextArea n="notes" />
				</div>
				{defaultValues?.id == null && (
					<div>
						<ITextArea n="goodsInfo.name" />
						<INumber n="goodsInfo.quantity" />
						<INumber n="goodsInfo.weight" />
						<IMultiSelect n="goodsInfo.constraints" l={lConstraints} />
					</div>
				)}
			</DFlex>
		</GenericForm>
	);
};
