import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { DEFAULT_PAGE_SIZE } from "../../../../../../common/models/src/lib/constants/grid.constants";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import type { ITruckRequestDto } from "../../../../../../common/models/src/lib/interfaces/truck.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormDatePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchSubcontractorsAction } from "../../../../../../subcontractors/data-access/src/lib/subcontractors.slice";
import {
	attachDocumentsToTruckAction,
	createTruckAction,
	updateTruckAction,
} from "../../../../../data-access/src/lib/trucks.slice";
import styles from "./truck-form.module.css";

interface TruckFormProps {
	formState: ITruckRequestDto | undefined;
	onClose: () => void;
}

export const TruckForm = ({ formState, onClose }: TruckFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const {
		truckTypes,
		constraints,
		subcontractors,
		currentBusinessUnit,
		loading,
	} = useAppSelector((state) => ({
		truckTypes: state.truckTypes.data,
		constraints: state.constraints.data,
		subcontractors: state.subcontractors.data,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
		loading: state.trucks.loading,
	}));

	const [truckTypeId, setTruckTypeId] = useState<number | undefined>(
		formState?.truckType?.id,
	);
	const [subcontractorId, setSubcontractorId] = useState<number | undefined>(
		formState?.subcontractor?.id,
	);
	const [constraintIds, setConstraintIds] = useState<number[]>([]);

	const onConstraintsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedConstraints = event.target.value;
		setConstraintIds(selectedConstraints.map((item) => item.id));
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const { files } = dataItem;

		const formData = new FormData();

		if (files?.length) {
			for (const file of files) {
				formData.append("files", file.getRawFile?.() || new Blob());
			}
		}

		const requestObject = {
			id: dataItem.id,
			name: dataItem.name,
			registration: dataItem.registration,
			nextMOTDate: dataItem.nextMOTDate,
			nextInspectionTime: dataItem.nextInspectionTime,
			truckTypeId: truckTypeId,
			subcontractorId: subcontractorId,
			constraintIds: constraintIds,
			notes: dataItem.notes,
		} as ITruckRequestDto;

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateTruckAction,
				payload: requestObject,
				successMessage: `Truck named ${formState.name} successfully updated.`,
				errorMessage: `Could not update truck named ${formState.name}.`,
			});

			updateTruckAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createTruckAction,
				payload: requestObject,
				successMessage: `Truck named ${dataItem.name} successfully created.`,
				errorMessage: `Could not create truck named ${dataItem.name}.`,
			});

			if (createTruckAction.fulfilled.match(actionResult)) {
				if (dataItem?.files?.length > 0) {
					const actionResultAttachments = await dispatch(
						addAttachmentsAction({
							documents: formData,
							entity: AttachmentUsage.Truck,
							entityId: actionResult.payload.id,
						}),
					);

					if (addAttachmentsAction.fulfilled.match(actionResultAttachments)) {
						dispatch(
							attachDocumentsToTruckAction({
								documents: actionResultAttachments.payload,
								entityId: actionResult.payload.id,
							}),
						);
					}
				}
				onClose();
			}
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchSubcontractorsAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch subcontractors.",
		});

		if (!formState) return;

		const listOfIds = [];
		for (const item of formState.constraints ?? []) {
			listOfIds.push(item.id);
		}

		setConstraintIds(listOfIds);
	}, [dispatchWithNotifications, formState]);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<CelerumDisabledInput
									value={currentBusinessUnit?.name}
									label="Business Unit"
								/>
								<Field
									id="name"
									name="name"
									label="Name"
									component={CelerumFormInput}
									maxLength={250}
									focused="true"
								/>
								<Field
									id="registration"
									name="registration"
									label="Registration Number"
									maxLength={12}
									component={CelerumFormInput}
									validator={requiredValidator}
									required
								/>
								<Field
									id="nextMOTDate"
									name="nextMOTDate"
									label="Next MOT Date"
									component={CelerumFormDatePicker}
									validator={requiredValidator}
									required
								/>
								{!formState && (
									<Field
										label="Upload Documents"
										id="files"
										name="files"
										component={CelerumFormUploadInput}
									/>
								)}
								<Field
									id="subcontractor"
									dataItemKey="id"
									label="Subcontractor"
									textField="name"
									name="subcontractor"
									component={CelerumFormDropDownList}
									onChange={(e) => setSubcontractorId(e.target.value.id)}
									data={subcontractors}
								/>
							</div>
							<div>
								<Field
									id="truckType"
									dataItemKey="id"
									label="Type"
									textField="name"
									name="truckType"
									component={CelerumFormDropDownList}
									onChange={(e) => setTruckTypeId(e.target.value.id)}
									data={truckTypes}
									validator={requiredValidator}
									required
								/>
								<Field
									id="constraints"
									name="constraints"
									label="Constraints Satisfied"
									dataItemKey="id"
									textField="name"
									data={constraints}
									onChange={onConstraintsDropdownChange}
									component={CelerumFormMultiSelect}
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									type="text"
								/>
								<Field
									id="nextInspectionTime"
									name="nextInspectionTime"
									label="Next Inspection Date"
									component={CelerumFormDatePicker}
									validator={requiredValidator}
									required
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
