import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { useEffect, useMemo, useState } from "react";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { ISubcontractorRequestDto } from "../../../../../../common/models/src/lib/interfaces/subcontractor.interface";
import type { VehicleType } from "../../../../../../common/models/src/lib/types/vehicle.type";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddItemsInInput } from "../../../../../../common/ui/src/lib/components/celerum-add-items-in-input/celerum-add-items-in-inputs.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createSubcontractorAction,
	updateSubcontractorAction,
} from "../../../../../data-access/src/lib/subcontractors.slice";
import styles from "./subcontractor-form.module.css";

interface SubcontractorFormProps {
	formState: ISubcontractorRequestDto | undefined;
	onClose: () => void;
}

export const SubcontractorForm = ({
	formState,
	onClose,
}: SubcontractorFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const {
		constraints,
		truckTypes,
		trailerTypes,
		currentBusinessUnit,
		loading,
	} = useAppSelector((state) => ({
		constraints: state.constraints.data,
		truckTypes: state.truckTypes.data,
		trailerTypes: state.trailerTypes.data,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
		loading: state.subcontractors.loading,
	}));

	const [isFormModified, setIsFormModified] = useState<boolean>(false);
	const [constraintIds, setConstraintIds] = useState<number[]>([]);
	const [truckTypeIds, setTruckTypeIds] = useState<number[]>([]);
	const [trailerTypeIds, setTrailerTypeIds] = useState<number[]>([]);
	const [phones, setPhones] = useState<string[]>(formState?.phones || []);
	const [emails, setEmails] = useState<string[]>(formState?.emails || []);
	const [truckRegistrationNumbers, setTruckRegistrationNumbers] = useState<
		string[]
	>(formState?.truckRegistrationNumbers || []);

	const vehicleTypes = useMemo(
		() => truckTypes.concat(trailerTypes) as VehicleType[],
		[truckTypes, trailerTypes],
	);

	const onConstraintsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedConstraints = event.target.value;
		setConstraintIds(
			selectedConstraints.map((constraint: IBase) => constraint.id),
		);
	};

	const onVehicleTypeDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedVehicleTypes = event.target.value;

		setTrailerTypeIds(
			selectedVehicleTypes
				.filter((type: VehicleType) => type.vehicleType === "TRAILER")
				.map((type: VehicleType) => type.id),
		);
		setTruckTypeIds(
			selectedVehicleTypes
				.filter((type: VehicleType) => type.vehicleType === "TRUCK")
				.map((type: VehicleType) => type.id),
		);
	};

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		const { id, name, notes } = event.values;

		if (!event.isValid) {
			return;
		}

		const requestObject = {
			id,
			name,
			emails,
			phones,
			truckTypeIds,
			trailerTypeIds,
			truckRegistrationNumbers,
			constraintIds,
			notes,
		};

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateSubcontractorAction,
				payload: requestObject as ISubcontractorRequestDto,
				successMessage: `Subcontractor ${requestObject.name} was successfully updated.`,
				errorMessage: "Could not update subcontractor.",
			});
			updateSubcontractorAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createSubcontractorAction,
				payload: requestObject as ISubcontractorRequestDto,
				successMessage: `Subcontractor ${requestObject.name} was successfully created.`,
				errorMessage: "Could not create subcontractor.",
			});
			createSubcontractorAction.fulfilled.match(actionResult) && onClose();
		}
	};

	useEffect(() => {
		if (!formState) return;

		setConstraintIds(
			formState.constraints.map((constraint: IBase) => constraint.id),
		);

		setTrailerTypeIds(
			formState.vehicleTypes
				.filter((type: VehicleType) => type.vehicleType === "TRAILER")
				.map((type: VehicleType) => type.id),
		);
		setTruckTypeIds(
			formState.vehicleTypes
				.filter((type: VehicleType) => type.vehicleType === "TRUCK")
				.map((type: VehicleType) => type.id),
		);
	}, [formState]);

	return (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.container}>
							<div>
								<CelerumDisabledInput
									value={currentBusinessUnit?.name}
									label="Business Unit"
								/>
								<Field
									id="name"
									name="name"
									label="Name"
									component={CelerumFormInput}
									maxLength={250}
									validator={requiredValidator}
									required
									focused="true"
								/>
								<CelerumAddItemsInInput
									label="Truck Registration Number"
									name="truckRegistrationNumber"
									id="truckRegistrationNumber"
									buttonName="Add another Truck Registration Number"
									result={truckRegistrationNumbers}
									setResult={setTruckRegistrationNumbers}
									setIsFormModified={setIsFormModified}
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									maxLength={500}
									component={CelerumFormTextArea}
									type="text"
								/>
							</div>
							<div>
								<Field
									id="vehicleTypes"
									name="vehicleTypes"
									label="Vehicle Types"
									dataItemKey="uniqueId"
									textField="name"
									data={vehicleTypes}
									onChange={onVehicleTypeDropdownChange}
									component={CelerumFormMultiSelect}
								/>
								<Field
									id="constraints"
									name="constraints"
									label="Constraints Satisfied"
									dataItemKey="id"
									textField="name"
									data={constraints}
									onChange={onConstraintsDropdownChange}
									component={CelerumFormMultiSelect}
								/>
								<CelerumAddItemsInInput
									label="Email"
									name="email"
									id="email"
									buttonName="Add another email"
									result={emails}
									setResult={setEmails}
									setIsFormModified={setIsFormModified}
								/>
								<CelerumAddItemsInInput
									label="Contact Number"
									name="contactNumber"
									id="contactNumber"
									buttonName="Add another phone number"
									result={phones}
									setResult={setPhones}
									setIsFormModified={setIsFormModified}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={
							(!isFormModified && !formRenderProps.allowSubmit) || loading
						}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
