import { SvgIcon } from "@progress/kendo-react-common";
import { useState } from "react";
import {
	arrowIcon,
	deliveryIconRounded,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { ModalType } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IGoodsResponseDto } from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import type { ITrailerType } from "../../../../../../common/models/src/lib/interfaces/trailer-type.interface";
import type { ITruckType } from "../../../../../../common/models/src/lib/interfaces/truck-type.interface";
import type { TagItem } from "../../../../../../common/models/src/lib/types/tag.type";
import { useAppDispatchWithNotifications } from "../../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumAddressCard } from "../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumConfirmModal } from "../../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import {
	getFormattedCurrency,
	getFormattedValue,
} from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import {
	generateCargoLabelAction,
	generateCmrAction,
} from "../../../../../data-access/src/lib/goods.slice";
import styles from "./goods-accordion-content.module.css";

interface GoodsAccordionContentProps {
	goods: IGoodsResponseDto;
}

export const GoodsAccordionContent = ({
	goods,
}: GoodsAccordionContentProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const [showModal, setShowModal] = useState<{ cargo: boolean; cmr: boolean }>({
		cargo: false,
		cmr: false,
	});

	const closeModal = () => {
		setShowModal({ cargo: false, cmr: false });
	};

	const handleGenerateCMR = async () => {
		const result = await dispatchWithNotifications({
			action: generateCmrAction,
			payload: goods?.id,
		});

		if (generateCmrAction.fulfilled.match(result)) {
			const data = result.payload;
			window.open(URL.createObjectURL(data));
		}
	};

	const handleGenerateCargoLabel = async () => {
		const result = await dispatchWithNotifications({
			action: generateCargoLabelAction,
			payload: goods?.id,
		});

		if (generateCargoLabelAction.fulfilled.match(result)) {
			const data = result.payload;
			window.open(URL.createObjectURL(data));
		}
	};

	const renderTags = (items: TagItem[]) => {
		if (!items) {
			return;
		}

		return items.map((item: TagItem) => (
			<div key={item.id} className={styles.tag}>
				<CelerumTag name={item.name} />
			</div>
		));
	};

	const renderDescriptionContainer = (
		label: string,
		value: string | number | IBase[] | ITruckType[] | ITrailerType[],
		lastElement?: boolean,
	) => (
		<>
			<div className={styles.descriptionContainer}>
				<div className={styles.label}>
					<p>{label}</p>
				</div>
				<div className={styles.tagValueContainer}>
					{typeof value === "number" || typeof value === "string"
						? value
						: renderTags(value)}
				</div>
			</div>
			{!lastElement && <div className={styles.horizontalLine} />}
		</>
	);

	return (
		<>
			<div className={styles.cardsContainer}>
				<CelerumAddressCard
					title="Collection"
					name={goods.collectionLocation?.name ?? NOT_AVAILABLE}
					address={goods.collectionLocation?.address ?? NOT_AVAILABLE}
					date={
						goods.collectionDate
							? formatDate(new Date(goods.collectionDate))
							: NOT_AVAILABLE
					}
				/>
				<SvgIcon icon={arrowIcon} width={50} />
				<CelerumAddressCard
					title="Delivery"
					name={goods.deliveryLocation?.name ?? NOT_AVAILABLE}
					address={goods.deliveryLocation?.address ?? NOT_AVAILABLE}
					date={
						goods.deliveryDate
							? formatDate(new Date(goods.deliveryDate))
							: NOT_AVAILABLE
					}
					icon={deliveryIconRounded}
				/>
			</div>
			<div>
				{renderDescriptionContainer(
					"Goods ID",
					goods?.uniqueId ?? NOT_AVAILABLE,
				)}
				{renderDescriptionContainer(
					"Special Requirements",
					goods.constraints.length ? goods.constraints : NOT_AVAILABLE,
				)}
				{renderDescriptionContainer(
					"Revenue",
					goods.revenue
						? getFormattedCurrency(goods.revenue, goods.currency?.code)
						: NOT_AVAILABLE,
				)}
				{renderDescriptionContainer(
					"Quantity",
					goods.quantity
						? getFormattedValue(goods.quantity, goods.currency?.code)
						: NOT_AVAILABLE,
				)}
				{renderDescriptionContainer(
					"Weight",
					goods.weight
						? `${getFormattedValue(goods.weight, goods.currency?.code)} kg`
						: NOT_AVAILABLE,
				)}
				{renderDescriptionContainer(
					"Notes",
					goods?.notes ?? NOT_AVAILABLE,
					true,
				)}
				<div className={styles.horizontalLine} />
				<div className={styles.buttonsContainer}>
					<CelerumButton
						title="Generate CMR"
						onClick={() => setShowModal((prev) => ({ ...prev, cmr: true }))}
					/>
					<CelerumButton
						title="Generate Cargo Label"
						onClick={() => setShowModal((prev) => ({ ...prev, cargo: true }))}
					/>
				</div>
			</div>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title={showModal.cargo ? "Generate Cargo Label" : "Generate CMR"}
				subtitle="The process generates a downloadable PDF file"
				handleClose={closeModal}
				isOpen={showModal.cargo || showModal.cmr}
				handleSubmit={() => {
					const { cargo, cmr } = showModal;

					cargo && handleGenerateCargoLabel();
					cmr && handleGenerateCMR();
				}}
			/>
		</>
	);
};
