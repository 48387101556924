import { plusIcon } from "@progress/kendo-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { directionArrowsIcon } from "../../../../../common/models/src/lib/constants/icon.constants";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IGoodsResponseDto } from "../../../../../common/models/src/lib/interfaces/goods.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { getFormattedCurrency } from "../../../../../common/utils/src/lib/helpers/currency.helpers";
import { fetchConstraintsAction } from "../../../../../constraints/data-access/src/lib/constraints.slice";
import { fetchLegsByJobIdAction } from "../../../../../legs/data-access/src/lib/legs.slice";
import {
	deleteGoodsAction,
	deleteGoodsFromLoadAction,
} from "../../../../data-access/src/lib/goods.slice";
import { GoodsCardContainer } from "../components/goods-card-container/goods-card-container.component";
import { GoodsForm } from "../components/goods-form/goods-form.component";
import styles from "./goods-information-feature.module.css";

interface GoodsInformationProps {
	className: string | undefined;
	parentType: ParentType;
	isJobInvoiced?: boolean;
	parentId?: string | undefined;
	uniqueId?: string | undefined;
}

export const GoodsInformationFeature = ({
	className,
	parentType,
	isJobInvoiced = false,
	parentId,
	uniqueId,
}: GoodsInformationProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();

	const { data, loading, total, customer } = useAppSelector((state) => ({
		data: state.goods.data,
		loading: state.goods.loading,
		total: state.goods.total,
		customer: state.jobs.job?.customer,
	}));

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedGoods, setSelectedGoods] = useState<
		IGoodsResponseDto | undefined
	>(undefined);

	const renderedGoods = useMemo(() => {
		if (selectedGoods) {
			return {
				...selectedGoods,
				collectionDate: new Date(selectedGoods.collectionDate),
				deliveryDate: new Date(selectedGoods.deliveryDate),
				revenue: Math.round(selectedGoods.revenue * 100) / 100,
				weight: Math.round(selectedGoods.weight * 100) / 100,
			};
		}
	}, [selectedGoods]) as IGoodsResponseDto;

	const revenueTotal = useMemo(() => {
		if (data.length) {
			return getFormattedCurrency(
				data.reduce((accumulator, current) => accumulator + current.revenue, 0),
				customer?.currency.code,
			);
		}
		return 0;
	}, [data, customer]);

	const closeModal = () => {
		setShowModal(false);
		setSelectedGoods(undefined);
	};

	const renderTitle = () => {
		switch (parentType) {
			case ParentType.Job:
				return `Goods (${total}) - ${revenueTotal}`;
			case ParentType.Load:
				return "Contained in this load";
			default:
				return `Goods (${total}) - ${revenueTotal}`;
		}
	};

	const renderButtonTitle = () => {
		switch (parentType) {
			case ParentType.Job:
				return "Split Goods";
			case ParentType.Load:
				return "Add Goods";
			default:
				return "Split Goods";
		}
	};

	const renderModalTitle = () => {
		if (parentType === ParentType.Job) {
			return selectedGoods ? `Goods ${selectedGoods.uniqueId}` : "Split Goods";
		}
		return selectedGoods ? `Goods ${selectedGoods.uniqueId}` : "Add Goods";
	};

	const handleDeleteGoods = async (id: number, name: string) => {
		if (parentType === ParentType.Job) {
			const actionResult = await dispatchWithNotifications({
				action: deleteGoodsAction,
				payload: id,
				successMessage: `Goods named ${name} were successfully deleted.`,
				errorMessage: `Could not delete goods named ${name}.`,
			});

			if (
				deleteGoodsAction.fulfilled.match(actionResult) &&
				parentId &&
				!Number.isNaN(Number.parseInt(parentId))
			) {
				dispatchWithNotifications({
					action: fetchLegsByJobIdAction,
					payload: Number(parentId),
					errorMessage: "Could not fetch legs.",
				});
			}
		} else if (parentType === ParentType.Load) {
			dispatchWithNotifications({
				action: deleteGoodsFromLoadAction,
				payload: { goodsId: id, loadId: parentId },
				successMessage: `Goods named ${name} were successfully removed from load.`,
				errorMessage: `Could not remove goods named ${name} from load.`,
			});
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<CelerumCustomCard
				title={renderTitle()}
				buttonName={renderButtonTitle()}
				buttonSvgIcon={
					parentType === ParentType.Job ? directionArrowsIcon : plusIcon
				}
				className={className}
				disabled={loading || isJobInvoiced}
				onClick={() => {
					if (parentType === ParentType.Job) {
						setShowModal(true);
					} else {
						parentId &&
							navigate(`/loads/${parentId}/goods?uniqueId=${uniqueId}`, {
								state: { from: window.location.pathname },
							});
					}
				}}
			>
				{loading ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : data.length ? (
					<GoodsCardContainer
						handleDeleteGoods={handleDeleteGoods}
						showEditButtonInHeader={
							!(parentType === ParentType.Load || isJobInvoiced)
						}
						showDeleteButtonInHeader={!isJobInvoiced}
						parentType={parentType}
						setSelectedGoods={(goods) => {
							setSelectedGoods(goods);
							setShowModal(true);
						}}
					/>
				) : (
					!loading && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>No goods found.</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>
			<CelerumModal
				visible={showModal}
				toggleDialog={closeModal}
				title={renderModalTitle()}
				width={ModalSize.Medium}
			>
				<GoodsForm
					formState={renderedGoods}
					parentId={parentId}
					parentType={parentType}
					onClose={closeModal}
				/>
			</CelerumModal>
		</>
	);
};
