import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { useAuditDialog } from "../../dialogs/useAuditDialog";
import { useDocumentsFormDialog } from "../../dialogs/useDocumentsFormDialog";
import { useGoodFormDialog } from "../../dialogs/useGoodFormDialog";
import { useJobFormDialog } from "../../dialogs/useJobFormDialog";
import { useLegFormDialog } from "../../dialogs/useLegFormDialog";
import { useLegNoteDialog } from "../../dialogs/useLegNoteDialog";
import { useLocationFormDialog } from "../../dialogs/useLocationFormDialog";
import { useMultiEditDialog } from "../../dialogs/useMultiEditDialog";
import { DFlex } from "../../display/DFlex";
import { DGrid } from "../../display/DGrid";
import {
	AttachmentUsageType,
	NewJobStatus,
	TableNameType,
	jobApi,
	toasted,
} from "../../helpers";
import { JobDetailsGoods } from "./JobDetailsGoods";
import { JobDetailsInformation } from "./JobDetailsInformation";
import { JobDetailsLegs } from "./JobDetailsLegs";
import { JobDetailsTitle } from "./JobDetailsTitle";

export const JobDetailsPage2 = () => {
	const params = useParams();
	const [openLegFormDialog, legFormDialog] = useLegFormDialog();
	const [openLegNoteFormDialog, CollectionNoteFormDialog] = useLegNoteDialog();
	const [openMultiEditDialog, multiEditDialog] = useMultiEditDialog();
	const [openAuditDialog, auditDialog] = useAuditDialog(TableNameType.Job);
	const [showLocationForm, locationFormDialog] = useLocationFormDialog();
	const [showJobForm, jobFormDialog] = useJobFormDialog(showLocationForm);
	const [showDocumentsForm, documentsFormDialog] = useDocumentsFormDialog();
	const [showGoodFromDialog, goodFormDialog] = useGoodFormDialog();
	const jobId = Number(params.id);
	const response = useQuery({
		queryKey: ["jobApi.bff.bffJobDetailsDetail", jobId],
		queryFn: () => jobApi.bff.bffJobDetailsDetail(jobId).then((x) => x.data),
	});
	const data = useMemo(() => response.data, [response.data]);
	const navigate = useNavigate();
	useTitle(
		data ? `Job ${data.id} - ${data.uniqueId}` : "Job details, loading...",
	);
	if (response.isError) return <DFlex center>Error loading job</DFlex>;
	if (!data) return <DFlex center>Loading...</DFlex>;
	return (
		<DGrid rows="50px 1fr">
			{auditDialog}
			{multiEditDialog}
			{legFormDialog}
			{CollectionNoteFormDialog}
			{locationFormDialog}
			{jobFormDialog}
			{documentsFormDialog}
			{goodFormDialog}
			<JobDetailsTitle
				data={data}
				onGoBackClick={() => navigate("/jobs2")}
				onActionClick={(x) => {
					switch (x) {
						case "Duplicate":
							showJobForm({
								id: jobId,
								isDuplicate: true,
								onSubmit: (x) => {
									showJobForm();
									navigate(`/jobs2/${x.id}`);
								},
							});
							break;
						case "Pause":
							toasted(
								jobApi.job
									.jobChangeStatusPauseCreate(jobId)
									.then(() => response.refetch()),
								"Pausing job",
							);
							break;
						case "Resume":
							toasted(
								jobApi.job
									.jobChangeStatusResumeCreate(jobId)
									.then(() => response.refetch()),
								"Resuming job",
							);
							break;
						case "Force Complete":
							toasted(
								jobApi.job
									.jobForceCompleteCreate(jobId)
									.then(() => response.refetch()),
								"Forcing job completion",
							);
							break;
						case "Delete":
							toasted(
								jobApi.job
									.jobChangeStatusCancelCreate(jobId)
									.then(() => response.refetch()),
								"Deleting job",
							);
							break;
						case "Generate File Front":
							toasted(
								jobApi.job
									.jobReportFileFrontDetail(jobId, { format: "blob" })
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating File Front",
							).then(() => response.refetch());
							break;
						case "Audit":
							openAuditDialog(jobId);
							break;
						case "Multi Edit":
							openMultiEditDialog({
								jobId,
								onSubmitClicked: () => response.refetch(),
							});
							break;
						case "Send to Invoice":
							toasted(
								jobApi.job
									.jobChangeStatusCreate(jobId, NewJobStatus.READY_FOR_INVOICE)
									.then(() => response.refetch()),
								"Sending to invoice",
							);
							break;
						case "Preview Invoice":
							toasted(
								jobApi.invoice
									.invoiceReportInvoiceDetail(jobId, { format: "blob" })
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Previewing Invoice",
							);
							break;
					}
				}}
			/>
			<DGrid columns="1fr 1.5fr">
				<DGrid rows="1fr 1fr">
					<JobDetailsInformation
						data={data}
						onEditClick={() =>
							showJobForm({
								id: jobId,
								onSubmit: () => {
									response.refetch();
									showJobForm();
								},
							})
						}
						onViewDocumentsClick={() =>
							showDocumentsForm({
								entityId: jobId,
								usage: AttachmentUsageType.Job,
								onChange: () => response.refetch(),
							})
						}
					/>
					<JobDetailsGoods
						data={data}
						onSplitGoodsClicked={() =>
							showGoodFromDialog({
								jobId,
								onSubmit: () => {
									response.refetch();
									showGoodFromDialog();
								},
							})
						}
						onEditGoodClicked={(id) =>
							showGoodFromDialog({
								id,
								onSubmit: () => {
									response.refetch();
									showGoodFromDialog();
								},
							})
						}
						onRemoveGoodClicked={(id) =>
							toasted(
								jobApi.goods.goodsDelete(id).then(() => response.refetch()),
								"Removing good",
							)
						}
						onGenerateCMRClicked={(id) =>
							toasted(
								jobApi.goods
									.goodsReportCmrDetail(id, {
										format: "blob",
									})
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating CMR",
							)
						}
						onGenerateCargoLabelClicked={(id) =>
							toasted(
								jobApi.goods
									.goodsReportCargoLabelDetail(id, {
										format: "blob",
									})
									.then((x) =>
										window.open(URL.createObjectURL(x.data), "_blank"),
									),
								"Generating cargo label",
							)
						}
					/>
				</DGrid>
				<JobDetailsLegs
					data={data}
					onAddLegClicked={() =>
						openLegFormDialog({
							jobId,
							onSubmit: () => {
								response.refetch();
								openLegFormDialog();
							},
						})
					}
					onEditLegClicked={(id) =>
						openLegFormDialog({
							id,
							onSubmit: () => {
								response.refetch();
								openLegFormDialog();
							},
						})
					}
					onRemoveLegClicked={(id) =>
						toasted(
							jobApi.leg.deleteLeg(id).then(() => response.refetch()),
							"Deleting leg",
						)
					}
					onViewDocumentsClicked={(id) =>
						showDocumentsForm({
							entityId: id,
							isPod: false,
							usage: AttachmentUsageType.Leg,
							onChange: () => response.refetch(),
						})
					}
					onAttachPODClicked={(id) =>
						showDocumentsForm({
							entityId: id,
							usage: AttachmentUsageType.Leg,
							isPod: true,
							onChange: () => response.refetch(),
						})
					}
					onCollectionNoteClicked={(id) =>
						openLegNoteFormDialog({
							legId: id,
							isCollectionNote: true,
						})
					}
					onDeliveryTicketClicked={(id) => openLegNoteFormDialog({ legId: id })}
					onSubcontractorOrderClicked={(id) =>
						toasted(
							jobApi.leg
								.legReportSubcontractorOrderDetail(id, {
									format: "blob",
								})
								.then((x) =>
									window.open(URL.createObjectURL(x.data), "_blank"),
								),
							"Generating subcontractor order",
						)
					}
					onLegNewStatusClicked={async (id, newStatus) => {
						await toasted(
							jobApi.leg.legChangeStatusCreate({
								legId: id,
								status: newStatus,
							}),
							"Changing leg status",
						);
						response.refetch();
					}}
				/>
			</DGrid>
		</DGrid>
	);
};
